import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AdsPOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import ThridScreenComponent from './ExamScreen';
import './styles.css'
export default function FirstScreenExam(props) {
  const {
    ExamData,
    choose, setChoose,
    AnswerArray, setAnswerArray,
  } = props;
  const [QuestionsData, setQuestionsData] = React.useState(ExamData.meta.exam_question_block_all);
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(true);
  const [QuestionsEditData, setQuestionsEditData] = React.useState(ExamData.meta.exam_question_block_all["item-0"]);
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState("item-0");

  const [QuestionsEditMode1, setQuestionsEditMode1] = React.useState(false);
  const [ArrayIndex, setArrayIndex] = React.useState("");
  const [isPlayingMedia, setIsPlayingMedia] = React.useState(false)
  const [QuestionsSolved, setQuestionsSolved] = React.useState(0);


  return (
    <div
      style={{ border: "1px solid #aaa", padding: "0px 0 10px", }}>

      {QuestionsEditMode ?
        <div>
          <div className="row" style={{ padding: "30px 30px" }}>
            <div className="col-md-8 button">
              <h2>{ExamData.title.rendered}</h2>
            </div>
            <div className="col-md-4" style={{ textAlign: 'right' }}>
              <a href="/">Dashboard</a> / <a href={slugs.chapter_wise_exam}>All Chapters</a>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2 col-1"></div>
            <div className="col-sm-8 col-10 form-view m-3">
              <h2>Questions</h2>

              <div>Questions that student solve are in unlimited time</div>

              <div style={{ display: "flex", margin: "10px 0 0 0", alignItems: "center" }}>
                <div style={{
                  border: "4px solid #1dbc5b",
                  borderRadius: "20px",
                  width: "25px",
                  height: "25px", display: "flex", justifyContent: "center", alignContent: "center",
                  fontSize: "12px", fontWeight: "bold", color: "#fff", backgroundColor: "#1dbc5b"
                }}>
                  &nbsp;1&nbsp;
                </div>
                &nbsp;- indicates Correct Answer
              </div>
              <div style={{ display: "flex", margin: "10px 0 0 0", alignItems: "center" }}>
                <div style={{
                  border: "4px solid #d5363a",
                  borderRadius: "20px",
                  width: "25px",
                  height: "25px", display: "flex", justifyContent: "center", alignContent: "center",
                  fontSize: "12px", fontWeight: "bold", color: "#fff", backgroundColor: "#d5363a"
                }}>
                  &nbsp;2&nbsp;
                </div>
                &nbsp;- indicates Wrong Answer
              </div>
              <div style={{ display: "flex", margin: "10px 0 0 0", alignItems: "center" }}>
                <div style={{
                  border: "4px solid #000",
                  borderRadius: "20px",
                  width: "25px",
                  height: "25px", display: "flex", justifyContent: "center", alignContent: "center",
                  fontSize: "12px", fontWeight: "bold",  backgroundColor: "#fff"
                }}>
                  &nbsp;3&nbsp;
                </div>
                &nbsp;- indicates Not Selected
              </div>

              <div className="row center">
                <div className="col-md-6 row center" >
                  {Object.keys(QuestionsData).map((item, index) => {
                    if (index < Object.keys(QuestionsData).length / 2)
                      return (
                        <div key={index}
                          onClick={() => {
                            setQuestionsEditData(QuestionsData[item])
                            setQuestionsEditKey(item)
                            setQuestionsEditMode(false)
                            setArrayIndex(index)

                          }}
                          className={(QuestionsData[item].question_description_type == "audio" ||
                            QuestionsData[item].question_media_type == "audio" ||
                            QuestionsData[item].answer_media_type == "audio") ?
                            "question-no-box-listening col-2 m-1 p-2 center" : "question-no-box-reading col-2 m-1 p-2 center"
                          }>
                          {QuestionsData[item].question_number}

                        </div>
                      )
                  })}
                </div>
                <div className="col-md-6 row">
                  {Object.keys(QuestionsData).map((item, index) => {
                    if (index >= Object.keys(QuestionsData).length / 2)
                      return (
                        <div key={index}
                          onClick={() => {
                            setQuestionsEditData(QuestionsData[item])
                            setQuestionsEditKey(item)
                            setQuestionsEditMode(false)
                            setArrayIndex(index)
                          }}
                          className={(QuestionsData[item].question_description_type == "audio" ||
                            QuestionsData[item].question_media_type == "audio" ||
                            QuestionsData[item].answer_media_type == "audio") ?
                            "question-no-box-listening col-2 m-1 p-2 center" : "question-no-box-reading col-2 m-1 p-2 center"
                          }>
                          {QuestionsData[item].question_number}
                        </div>
                      )
                  })}
                </div>
              </div>
            </div>
          </div>
         </div>
        :
        <div className="row">
          <div className="col-sm-2 col-1"></div>

          <div className="col-sm-8 col-10 form-view m-3">

            {!QuestionsEditMode1 ?
              <ThridScreenComponent
                keyValue={QuestionsEditKey}
                oneQuestionData={QuestionsEditData}
                AllQuestionData={QuestionsData}
                setAllQuestionData={setQuestionsData}
                AnswerArray={AnswerArray}
                ArrayIndex={ArrayIndex}
                setQuestionsSolved={setQuestionsSolved}
                QuestionsSolved={QuestionsSolved}
                // mediaPlayed={mediaPlayed} setMedaiPlayed={setMedaiPlayed}
                setIsPlayingMedia={setIsPlayingMedia}
                isPlayingMedia={isPlayingMedia}

              />
              :
              <div style={{ minHeight: "72vh" }}>
              </div>
            }
            <div className="row mx-5 m-3" style={{ backgroundColor: "#fff" }} >
              <div className="col-md-4">
                {ArrayIndex !== 0 &&
                  <div className="col-12 previous-question" style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (ArrayIndex !== 0) {
                        if (!isPlayingMedia) {
                          setQuestionsEditMode1(true)
                          setTimeout(() => {
                            setQuestionsEditMode1(false)
                          }, 0)
                          setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex - 1]])
                          setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex - 1])
                          setArrayIndex(ArrayIndex - 1)
                        }
                      }
                    }}
                  >
                    <button className={!isPlayingMedia ? "sign-in-button-11" : "sign-in-button-1-media-on"}>
                      <i className="fa fa-chevron-left" />&nbsp; Previous Question
                    </button>
                  </div>
                }
              </div>
              <div className="col-md-4 " style={{ textAlign: 'center', cursor: "pointer" }}
                onClick={() => {
                  if (!isPlayingMedia) {
                    setQuestionsEditMode(true)
                  }
                }}
              >
                <button className={!isPlayingMedia ? "sign-in-button-11" : "sign-in-button-1-media-on"}>
                  <i className="fa fa-list" />&nbsp;   Question List
                </button>
              </div>
              {ArrayIndex !== Object.keys(QuestionsData).length - 1 &&
                <div className="col-md-4 question-list" style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!isPlayingMedia) {
                      setQuestionsEditMode1(true)
                      setTimeout(() => {
                        setQuestionsEditMode1(false)
                      }, 0)
                      setQuestionsEditData(QuestionsData[Object.keys(QuestionsData)[ArrayIndex + 1]])
                      setQuestionsEditKey(Object.keys(QuestionsData)[ArrayIndex + 1])
                      setArrayIndex(ArrayIndex + 1)
                    }
                  }}
                >
                  <button className={!isPlayingMedia ? "sign-in-button-11" : "sign-in-button-1-media-on"}>
                    Next Question&nbsp;  <i className="fa fa-chevron-right" />
                  </button>
                </div>
              }
            </div>

          </div>
        </div>
      }
    </div >
  )
}
